import React from 'react';
import Link from "../../../components/Link/Link";

const Expired = ({onResend}) => {
    return (
        <div className="flex-row registrationForm">
            <div className="card loginCard position-relative">
                <div className="card-body pl-0 pr-0">
                    <div className="content">
                        <div className="feedBackLogo text-center mb-3">
                            <img width="100px" src="../../../../images/reputationPro.png" alt="feedBackIcon"/>
                        </div>

                        <div className="registerCardText ">
                            <h4 className="font-weight-normal mt-4 text-center p-2">
                                Link Expired.
                                This invitation link has expired. If you wish to request a new link, please click below:

                            </h4>
                            <div className="float-left fullWidth text-center mt-2">
                                <Link buttonText="Resend" onClick={onResend} className="btn btn-dark blueBtn mr-2"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Expired;