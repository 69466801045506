import React, {Component} from 'react';

class Input extends Component {
    render() {
        return (
            <div className="float-left w-100 readableInputs mb-2">
                <span>{this.props.label}</span>
                <div className="inputText">
                    <span>{this.props.value}</span>
                </div>
            </div>
        );
    }
}

export default Input;
