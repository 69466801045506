import React from 'react';

const Error = ({error}) => {
    return (
        <div className="flex-row registrationForm">
            <div className="card loginCard position-relative">
                <div className="card-body pl-0 pr-0">
                    <div className="content">
                        <div className="feedBackLogo text-center mb-3">
                            <img width="100px" src="../../../../images/reputationPro.png" alt="feedBackIcon"/>
                        </div>
                        <div className="registerCardText">
                            <img src="../../../../../images/attention.png" alt="error icon"/>
                            <p className="font-weight-normal mt-4 errorText">{error}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Error;