import React, {Component} from 'react';
import Table from "./components/Table";
import Api from "../../../helpers/Api";
import {Redirect, withRouter} from 'react-router'
import TableHeader from "../../components/HeaderTable/TableHeader";
import Link from "../../components/Link/Link";

class AddCC extends Component {
    
    
    constructor(props) {
        super(props);
        
        this.state = {
            rows: Api.selected,
            columns: [
                {name: "Company", key: "company_name", sortable: true},
                {name: "User", key: "user", sortable: true},
                {name: "Email", key: "email", sortable: true},
                {name: "Additional Emails", key: "phone", sortable: true}
            ],
        };
        this.table = React.createRef();
        
        console.log("Component will be constructed");
        console.log(this.props.history.location.state);
        if (this.props.history.location.state) {
            this.state = this.props.history.location.state;
            console.log("Replaced state from history");
        }
    }
    
    componentWillMount() {
        console.log("Component will mount again");
    }
    
    
    getSelection = () => {
        let selected = [];
        this.state.rows.map(item => {
            
                selected.push({guid:item.guid,cc:item.cc});
            
            return item;
        });
        console.log("Selected: ",selected);
        console.log("Rows: ",this.state.rows);
        return selected;
    };
    
    onSendInvitesPressed = () => {
        console.log("Send confirmation pressed");
        console.log(this.getSelection());
        Api.sendTo = this.getSelection();
        this.props.history.replace('invites', this.state);
        this.props.history.push('sendConfirmation');
    };
    
    componentWillUnmount() {
        console.log("Component will unmount");
        
    }
    
    onChangeCC = (index, cc) => {
        this.state.rows[index].cc = cc;
    };
    
    
    
    render() {
        if (this.state.rows.length === 0) {
            return (
                <Redirect to="invites"/>
            )
        }
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card bg-light">
                        
                        <TableHeader heading="Clients" searchKeyword={this.state.keyword} loading={this.state.loading}
                                     onSearchInputChanged={this.onSearchInputChanged}/>
                        
                        <div className="card-body bg-white text-center p-0">
                            {this.state.loading === false && this.state.rows.length === 0 &&
                            <div className="noRequest">No records found</div>
                            }
                            {this.state.rows.length > 0 &&
                            <Table items={this.state.rows} columns={this.state.columns} ref={this.table}
                                   onChangeCC={this.onChangeCC}/>
                            }
                        </div>
                        
                        
                        <div className="card-footer bg-white  border">
                            <div className="float-right">
                                <Link className="btn btn-dark blueBtn mr-2" onClick={this.onSendInvitesPressed}
                                      buttonText="Send Invites" disabled={this.getSelection().length === 0}/>
                            
                            </div>
                        </div>
                    
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddCC);
