import React, {Component} from 'react';
import Api from "../../../helpers/Api";
import LoaderSmall from "../../components/Loader/LoaderSmall";

let imgUrl = '../../../images/background.jpg';


class Login extends Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            username: '',
            password: '',
            error: null,
            loading: false
        }
    }
    
    onLogin = (user) => {
        this.props.onLogin(user);
        this.props.history.push('invites');
    };
    
    login = (event) => {
        this.setState({loading: true});
        event.preventDefault();
        Api.call("/users/login", "POST", this.state)
        .then(this.onLogin)
        .catch((error) => {
            this.setState({error: error, loading: false});
        })
    };
    
    render() {
        return (
            <div className="app flex-row align-items-center loginForm"
                 style={{
                     backgroundImage: 'url(' + imgUrl + ')',
                     backgroundSize: 'cover',
                     backgroundRepeat: 'no-repeat',
                 }}>
                <div className="card loginCard">
                    <div className="card-body">
                        <div className="logoContent">
                            <img  src="../../../images/zingit-logo.png" alt="logo"/>
                            <div className="logoText">
                                <h2>Sign In</h2>
                                <span>Enter your detail below</span>
                            </div>
                        </div>
                        {this.state.loading === false &&
                            <div className="text-danger">{this.state.error}</div>
                        }
                        <form onSubmit={this.login}>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input type="email" id="inputEmail" className="form-control"
                                           placeholder="Email address"
                                           value={this.state.username} onChange={(evt) => {
                                        this.setState({username: evt.target.value});
                                    }}
                                           required="required" autoFocus="autofocus"/>
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="form-label-group">
                                    <input type="password" id="inputPassword" className="form-control"
                                           placeholder="********"
                                           value={this.state.password} onChange={(evt) => {
                                        this.setState({password: evt.target.value});
                                    }}
                                           required="required"/>
                                </div>
                            </div>
                            <div className="float-left w-100 text-center">
                                {this.state.loading &&
                                <LoaderSmall/>
                                }
                                {!this.state.loading &&
                                <input type="submit" value="Login"
                                       className="btn blueBtn text-center w-75 blueColor"/>
                                }
                            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;
