import React, {Component} from 'react';
import Link from "../../../components/Link/Link";

class Preview extends Component {
    render() {
        return (
            <div className="card">
                <div className="card-header bg-white">
                    Preview
                </div>
                <div className="card-body bg-white text-center">
                    <div className="w-75 m-auto">
                        <h4 className="font-weight-normal text-left">Dear Artyom,</h4>
                        <div className="float-left w-100 text-justify previreDetail">
                        <span className=" d-block font-weight-normal">
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                            a galley of type and scrambled it to make a type specimen book.<br/>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has
                            been the industry's standard dummy text ever since the 1500s, when an unknown printer took
                            a galley of type and scrambled it to make a type specimen book.
                        </span>
                        </div>
                        <Link className="btn btn-dark darkBlueBtn mt-4" buttonText="Accept"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default Preview;
