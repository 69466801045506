import React, {Component} from 'react';
import TableHeader from "../components/HeaderTable/TableHeader";
import Api from "../../helpers/Api";
import Table from "../Invites/Clients/components/Table";
import Link from "../components/Link/Link";

class Requests extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            columns: [{name:"",key:null},{name:"Company",key:"company_name"}, {name:"User",key:"user"}, {name:"Email",key:"email"}, {name:"Phone",key:"phone"}],
            loading: true,
            checkAll : false
        };
        this.table = React.createRef();
    }

    componentWillMount() {
        this.loadData();
    }

    loadData = () => {
        Api.call("/requests")
        .then((requests) => {
            this.setState({rows: requests, loading: false},()=>{
                if(requests.length) {
                    this.table.current.update();
                }
            })
        });
    };

    getChecked() {
        let checked = [];
        this.state.rows.map(item => {
            if (item.checked) {
                checked.push(item);
            }
            return item;
        });
        return checked;
    }

    checkAll = () => {
        this.state.checkAll = !this.state.checkAll;

        let rows = this.state.rows.map(item => {
            item.checked = this.state.checkAll;
            return item;
        });

        this.setState({rows: JSON.parse(JSON.stringify(rows))});
        this.table.current.update();
    };

    onReject = () => {

        Api.call("/requests/reject", "POST", {requests: this.getChecked().map(request => request.id)})
            .then(this.onRejected)
            .catch(this.onError);
    };

    onResend = () => {
        Api.sendTo = this.getChecked().map(request => request.guid);
        Api.markRequestsAsSent = this.getChecked().map(request => request.id);

        this.props.history.push('sent');
    };

    onRejected = () => {
        this.loadData();
    };

    onError = (error) => {
        alert(error);
    };


    onChange = (index, checked) => {
        this.state.rows[index].checked = checked;
        this.setState({},
            () => {
                this.table.current.update();
            });
    };

    render() {

        if (this.state.rows.length === 0 && !this.state.loading) {
            return (
                <div id="content-wrapper" className="mt-3">
                    <div className="container-fluid">
                        <div className="card">


                            <TableHeader heading="Requests"/>


                            <div className="noRequest">There are no pending requests.</div>
                        </div>
                    </div>
                </div>
            )
        }
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card">
                        
                        
                        <TableHeader heading="Requests"/>

                        <div className="card-body bg-white text-center p-0">
                            <Table items={this.state.rows} columns={this.state.columns} ref={this.table}
                                   onChange={this.onChange}/>
                        </div>


                        <div className="card-footer bg-white border">
                            <div className="float-left">
                                <input type="checkbox" id="checkAll" checked={this.state.checkAll}
                                       onChange={this.checkAll} value="1"/>
                                <label htmlFor="checkAll" className="ml-2">Select All</label>
                            </div>
                            <div className="float-right">
                                <Link className="btn btn-dark blueBtn mr-2" buttonText="Resend" onClick={this.onResend}/>
                                <Link className="btn btn-dark redBtn" buttonText="Reject" onClick={this.onReject}/>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        );
    }
}

export default Requests;
