import React, {Component} from 'react';
import Api from '../../../helpers/Api';
import {Redirect, withRouter} from 'react-router';
import Link from "../../components/Link/Link";


class RegisterConfirmation extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selected: Api.selected,
            alreadySent: [],
            loading: false,
            error: null,
            columns: [
                {name: "", key: "", sortable: false},
                {name: "User", key: "user", sortable: true},
                {name: "Email", key: "email", sortable: true},
                {name: "Phone", key: "phone", sortable: true}
            ],
            sendTo: Api.sendTo
        };
        
        this.table = React.createRef();
    }
    
    componentWillMount() {
        let guids = this.state.selected.map(item => {
            return item.guid;
        });
    }
    
    
    onSend = (skip = false) => {
        Api.sendTo = Api.selected.map(item => {
            return item.guid;
        });
        
        
        this.props.history.push('register');
    };
    
    
    static clientInArray(arr, client) {
        for (let i = 0; i < arr.length; i++) {
            if (client.guid === arr[i].guid) {
                return true;
            }
        }
        return false;
    }
    
    onSkip = () => {
        this.onSend(true);
    };
    onCancel = () => {
        this.props.history.push('invites');
    };
    
    onResend = () => {
        this.onSend(false);
    };
    
    getChecked = () => {
        let checked = [];
        
        this.state.alreadySent.map(item => {
            if (item.checked) {
                checked.push(item);
            }
            return item;
        });
        return checked;
    };
    
    
    onChange = (index, checked) => {
        this.state.alreadySent[index].checked = checked;
        this.setState({},
            () => {
                this.table.current.update();
            });
    };
    
    render() {
        
        if (this.state.selected.length === 0) {
            return (
                <Redirect to="invites"/>
            )
        }
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card mb-3">
                        <div className="card-body bg-white text-center pt-5 pb-5">
                            <img src="../../../../images/invitation.png" alt="Invitaion Imager"/>
                            <h4 className="font-weight-normal mt-4">
                                Registration
                            </h4>
                            <span>Are you sure you want to register {this.state.sendTo.length} clients?</span>
                        </div>
                        <div className="card-footer bg-white text-right">
                            <Link className="btn btn-dark blueBtn mr-2" onClick={this.onSend}
                                  buttonText="Send"/>
                            <Link className="btn btn-dark redBtn" onClick={this.onCancel}
                                  buttonText="Cancel"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(RegisterConfirmation);
