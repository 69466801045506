import React, {Component} from 'react';
import {withRouter} from 'react-router';
import queryString from 'query-string';
import Api from "../../../helpers/Api";
import Expired from "./components/Expired";
import Error from "./components/Error";
import Resent from "./components/Resent";
import Input from "./components/Input";
import Checkbox from "./components/Checkbox";
import Link from "../../components/Link/Link";
import Status from "../Status/Status";
import LoaderSmall from "../../components/Loader/LoaderSmall";
import Loader from "react-loader-spinner";

class Registration extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            guid: queryString.parse(this.props.location.search).guid,
            loading: true,
            invalid: false,
            expired: false,
            error: null,
            resent: false,
            registering: false,
            registered: false,
            reputationProTermsAccepted: false,
            iVisionTermsAccepted: false,
            alreadyRegistered: false
        }
    }
    
    componentWillMount() {
        Api.call("/invites/validate", "POST", {guid: this.state.guid})
        .then(state => {
            this.setState({
                invalid: state.invalid,
                expired: state.expired,
                loading: false,
                client: state.client,
                alreadyRegistered: state.alreadyRegistered
            })
        }).catch(error => {
            console.log(error);
            this.setState({error: error, loading: false});
        });
    }
    
    
    onResend = () => {
        this.setState({loading: true});
        Api.call("/requests", "POST", {guid: this.state.guid})
        .then(request => {
            this.setState({resent: true, loading: false});
        }).catch(error => {
            this.setState({error: error, loading: false});
        })
    };
    
    onStatus = () => {
        this.props.history.push('status');
    };
    
    register = () => {
        this.setState({registering: true});
        Api.call("/registration/register", "POST", {guid: this.state.guid})
        .then(response => {
            this.setState({registering: false, registered: true});
            console.log(response);
        })
        .catch(error => {
            this.setState({error: "An error has occurred while registering."});
        })
    };
    
    render() {
        
        if (this.state.loading) {
            return (
                <div className="flex-row registrationForm">
                    <div className="card loginCard position-relative">
                        <div className="card-body pl-0 pr-0">
                            <div className="content">
                                <div className="feedBackLogo text-center mb-3">
                                    <img width="100px" src="../../../../images/reputationPro.png" alt="feedBackIcon"/>
                                </div>
                                <div className="registerCardText">
                                    <Loader type="TailSpin" color="#000000" height={40} width={40}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
        
        if (this.state.invalid) {
            return (
                
                <Error error="Invalid Invite Code"/>
            )
        }
        
        
        if (this.state.error) {
            return (
                <Error error={this.state.error}/>
            )
        }
        
        
        if (this.state.alreadyRegistered) {
            return (
                <Error
                    error="You have already registered for Reputation Pro. If you are facing any issues, please contact support."/>
            
            )
        }
        
        if (this.state.resent) {
            return (
                <Resent/>
            )
        }
        if (this.state.expired) {
            return (
                <Expired onResend={this.onResend}/>
            )
        }
        
        if (this.state.registered) {
            return (
                <Status/>
            )
        }
        
        return (
            
            <div className="flex-row registrationForm">
                <div className="card loginCard mb-3">
                    <div className="card-body">
                        <div className="content">
                            <div className="feedBackLogo text-center mb-3">
                                <img width="100px" src="../../../../images/reputationPro.png"
                                     alt="feedBackIcon"/>
                            </div>
                            
                            Zingit is requiring you to confirm this is your account to upgrade to the reputation
                            management & monitoring system.
                        
                        
                        </div>
                        
                        <Input label="Company Name" value={this.state.client.company_name}/>
                        <Input label="Email" value={this.state.client.email}/>
                        <Input label="Contact Name"
                               value={this.state.client.first_name + " " + this.state.client.last_name}/>
                        <Input label="Phone Number" value={this.state.client.phone}/>
                        <span className="warningText">If this information is incorrect, please contact support at 866-587-5572 x 2</span>
                        <Checkbox
                            label="I agree that this application might make changes to my Zingit Solutions account"
                            id="1" onChange={() => {
                            this.setState({iVisionTermsAccepted: !this.state.iVisionTermsAccepted});
                        }}/>
                        <Checkbox label="I agree to Reputation Pro terms & conditions" id="2" onChange={() => {
                            this.setState({reputationProTermsAccepted: !this.state.reputationProTermsAccepted});
                        }}/>
                        <div className="float-left fullWidth text-center mt-2">
                            {this.state.registering &&
                            <LoaderSmall/>
                            }
                            {!this.state.registering &&
                            <Link buttonText="Submit"
                                  disabled={!(this.state.iVisionTermsAccepted && this.state.reputationProTermsAccepted)}
                                  showLoader={this.state.registering} onClick={this.register}
                                  className="btn btn-dark blueBtn mr-2"/>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
            ;
    }
}

export default withRouter(Registration);
