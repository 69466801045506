import React, {Component} from 'react';
import Search from "./component/Search";
import LoaderSmall from "../Loader/LoaderSmall";
import Link from "../Link/Link";

class TableHeader extends Component {
    
    render() {
        return (
            <div className="card-header bg-white withSearch border">
                <div className="float-left flex-row d-flex">
                    <span className="mr-2">{this.props.heading}</span>
                    {this.props.loading &&
                    <LoaderSmall/>
                    }
                </div>
                <div className="float-right">
                    {!this.props.hideSearch &&
                    <div className="float-left">
                        <Search searchKeyword={this.props.searchKeyword}
                                onSearchInputChanged={this.props.onSearchInputChanged}/>
                    </div>
                    }
                    <div className="float-left">
                        {this.props.showSync === true &&
                        <Link className="btn btn-dark ml-2 mr-2" onClick={this.props.onSyncPressed}
                              buttonText={this.props.syncing === true ? "Syncing..." : "Sync"}/>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default TableHeader;
