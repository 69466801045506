import React, {Component} from 'react';

class Status extends Component {
    render() {
        return (
            <div className="flex-row registrationForm">
                <div className="card loginCard position-relative">
                    <div className="card-body pl-0 pr-0">
                        <div className="content">
                            <div className="feedBackLogo text-center mb-3">
                                <img width="100px" src="../../../../images/reputationPro.png" alt="feedBackIcon"/>
                            </div>
                            <div className="registerCardText">
                                <img width="32px" src="../../../../../images/checked.png" alt="Checked Icon"/>
                                <p className="font-weight-normal mt-4 text-center p-2">
                                    Your Reputation Pro account has been successfully setup.
                                    You will receive a confirmation email shortly.

                                </p>
                                <span>Thank you!</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Status;