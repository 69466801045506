import React, {Component} from 'react';
import InvitationDetail from "./components/InvitationDetail";
import Preview from "./components/Preview";
import NotificationDetail from "../ResendConfirmation/components/NotificationDetail";
import Table from "../Clients/components/Table";
import NotificationButton from "../ResendConfirmation/components/NotificationButton";
import Api from '../../../helpers/Api';
import {Redirect, withRouter} from 'react-router';
import DefaultLoader from "../../components/Loader/DefaultLoader";


class SendConfirmation extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            selected: Api.selected,
            alreadySent: [],
            loading: true,
            error: null,
            columns: [
                {name: "", key: "", sortable: false},
                {name: "User", key: "user", sortable: true},
                {name: "Email", key: "email", sortable: true},
                {name: "Phone", key: "phone", sortable: true}
            ],
            sendTo: Api.selected
        };
        
        this.table = React.createRef();
    }
    
    componentWillMount() {
        let guids = this.state.selected.map(item => {
            return item.guid;
        });
        
        Api.call("/invites/sent", "POST", {accounts: guids})
        .then(alreadySent => {
            this.setState({alreadySent: alreadySent, loading: false}, () => {
                if (alreadySent.length > 0 && !this.state.loading) {
                    this.table.current.update();
                }
            });
        }).catch(error => this.setState({error: error}));
    }
    
    onSendConfirmation = () => {
        Api.sendTo = this.state.sendTo;
        this.props.history.push('sent');
        
    };
    
    onSend = (skip = false) => {
        let all = this.state.selected.map(item => {
            return item;
        });
        
        let alreadySent = this.state.alreadySent.map(item => {
            return item;
        });
        
        let alreadySentChecked = [];
        this.state.alreadySent.map(item => {
            if (item.checked) {
                alreadySentChecked.push(item);
            }
            return item;
        });
        
        console.log(all);
        
        console.log("alreadySent",alreadySent);
        console.log(alreadySentChecked);
        
        let toSend = [];
        all.map(item => {
            if (!SendConfirmation.clientInArray(alreadySent,item)) {
                toSend.push({guid:item.guid,cc:item.cc});
                console.log(item);
                console.log("Adding to toSend because user not found in alreadySent");
                return item;
            }
            if (skip === false) {
                if (SendConfirmation.clientInArray(alreadySentChecked,item)) {
                    
                        toSend.push({guid: item.guid, cc: item.cc})
                    
                }
                
            }
            return item;
        });
        
        console.log(toSend);
        this.setState({sendTo: toSend, alreadySent: []});
    };
    
    
    static clientInArray(arr, client){
        for(let i=0;i<arr.length;i++){
            if(client.guid === arr[i].guid){
                return true;
            }
        }
        return false;
    }
    
    onSkip = () => {
        this.onSend(true);
    };
    onCancel = () => {
        this.props.history.push('invites');
    };
    
    onResend = () => {
        this.onSend(false);
    };
    
    getChecked = () => {
        let checked = [];
        
        this.state.alreadySent.map(item => {
            if (item.checked) {
                checked.push(item);
            }
            return item;
        });
        return checked;
    };
    
    
    onChange = (index, checked) => {
        this.state.alreadySent[index].checked = checked;
        this.setState({},
            () => {
                this.table.current.update();
            });
    };
    
    render() {
        
        if (this.state.selected.length === 0) {
            return (
                <Redirect to="invites"/>
            )
        }
        
        console.log("Rendering SendConfirmation");
        if (this.state.loading) {
            return (
                <div id="content-wrapper" className="mt-3">
                    
                    <div className="container-fluid">
                        
                        <div className="card mb-3">
                            <div className="card-body bg-white text-center pt-5 pb-5">
                                <DefaultLoader/>
                                <span>Loading, please wait...</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.alreadySent.length > 0) {
            return (
                <div id="content-wrapper" className="mt-3">
                    <div className="container-fluid">
                        <div className="card">
                            <NotificationDetail total={this.state.alreadySent.length}/>
                            
                            <div className="card-body bg-white text-center p-0">
                                <Table items={this.state.alreadySent} columns={this.state.columns} ref={this.table}
                                       onChange={this.onChange}/>
                            </div>
                            
                            <NotificationButton checked={this.getChecked().length} onResend={this.onResend}
                                                skipActive={this.state.selected.length !== this.state.alreadySent.length}
                                                onSkip={this.onSkip}  onCancel={this.onCancel}
                                                resendActive={this.getChecked().length > 0}/>
                        
                        
                        </div>
                    </div>
                </div>
            );
        }
        
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    
                    <InvitationDetail total={this.state.sendTo.length} onSendConfirmation={this.onSendConfirmation}/>
                    
                    <Preview/>
                </div>
            </div>
        
        );
    }
}

export default withRouter(SendConfirmation);
