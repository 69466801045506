import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import './App.scss';
import Login from "./views/Users/Login/Login";
import Forgot from "./views/Users/ForgotPassword/Forgot";
import Layouts from "./views/Layouts";
import PrivateRoute from "./views/components/PrivateRoute";
import Api from "./helpers/Api";
import SendConfirmation from "./views/Invites/SendConfirmation/SendConfirmation";
import InvitationSuccess from "./views/Invites/Sent/InvitationSuccess";
import InviteLogs from "./views/Logs/InviteLogs";
import Requests from "./views/Requests/Requests";
import Registration from "./views/Registration/RegistrationForm/Registration";
import RegistrationRoute from "./views/components/RegistrationRoute";
import Status from "./views/Registration/Status/Status";
import {Redirect} from "react-router";
import Import from "./views/Import/Import";
import AddCC from "./views/Invites/AddCC/AddCC";
import DirectRegistration from "./views/Registration/RegistrationForm/DirectRegistration";
import RegisterConfirmation from "./views/Invites/SendConfirmation/RegisterConfirmation";


class App extends Component {
    
    static currentRoute = 'invites';
    
    constructor(props) {
        super(props);
        this.state = {
            user: Api.getUser()
        }
    }
    
    onLogin = (user) => {
        Api.setUser(user);
        this.setState({user: user});
        console.log("User logged in: " + JSON.stringify(user));
    };
    
    render() {
        return (
            <Switch>
                <Route path="/" exact name="home"
                       render={(props) => <Redirect to="invites"/>}/>
                <Route path="/login" exact name="login"
                       render={(props) => <Login {...props} onLogin={this.onLogin}/>}/>
                <Route path="/forgot" exact name="forgot" component={Forgot}/>
                <PrivateRoute path="/invites" name="invites" user={this.state.user} component={Layouts}/>
                <PrivateRoute path="/sendConfirmation" name="sendConfirmation" user={this.state.user}
                              component={SendConfirmation}/>
                <PrivateRoute path="/addCC" name="addCC" user={this.state.user}
                              component={AddCC}/>
                <PrivateRoute path="/sent" name="sent" user={this.state.user}
                              component={InvitationSuccess}/>
                <PrivateRoute path="/logs" name="logs" user={this.state.user}
                              component={InviteLogs}/>
                <PrivateRoute path="/import" name="import" user={this.state.user}
                              component={Import}/>
                <PrivateRoute path="/requests" name="requests" user={this.state.user}
                              component={Requests}/>
                <RegistrationRoute path="/registration" name="registration" user={this.state.user}
                                   component={Registration}/>
                <PrivateRoute path="/register" name="directRegistration" user={this.state.user}
                                   component={DirectRegistration}/>
                <PrivateRoute path="/registerConfirmation" name="registerConfirmation" user={this.state.user}
                                   component={RegisterConfirmation}/>
                <RegistrationRoute path="/status" name="status" user={this.state.user}
                                   component={Status}/>
            </Switch>
        );
    }
}

export default App;