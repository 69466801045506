import React, {Component} from 'react';
import Pagination from "./components/Pagination";
import Table from "./components/Table";
import Api from "../../../helpers/Api";
import {withRouter} from 'react-router'
import TableHeader from "../../components/HeaderTable/TableHeader";
import Link from "../../components/Link/Link";

class Clients extends Component {
    
    
    constructor(props) {
        super(props);
        
        this.state = {
            rows: [],
            columns: [
                {name: "", key: null, sortable: false},
                {name: "Company", key: "company_name", sortable: true},
                {name: "Account #", key: "internal_id", sortable: true},
                {name: "User", key: "user", sortable: true},
                {name: "Email", key: "email", sortable: true},
                {name: "Phone", key: "phone", sortable: true}
            ],
            error: null,
            perPage: 10,
            currentPage: 1,
            totalPages: 1,
            step: 1,
            selected: [],
            keyword: '',
            checkAll: false,
            loading: true,
            sort: "company_name",
            sortDirection: true,
            totalRecords: 0
        };
        this.table = React.createRef();
        
        console.log("Component will be constructed");
        console.log(this.props.history.location.state);
        if (this.props.history.location.state) {
            this.state = this.props.history.location.state;
            console.log("Replaced state from history");
        }
    }
    
    componentWillMount() {
        this.loadData();
        console.log("Component will mount again");
    }
    
    onDataLoaded = (data, requestId) => {
        console.log(data);
        this.setState({
            rows: data.data,
            totalRecords: data.totalRecords,
            totalPages: Math.ceil(data.totalRecords / this.state.perPage),
            loading: false
        }, () => {
            if (this.state.rows.length > 0) {
                this.table.current.update();
            }
        });
        
        
    };
    
    getSelection = () => {
        let selected = [];
        this.state.rows.map(item => {
            if (item.checked) {
                selected.push(item);
            }
            return item;
        });
        return selected;
    };
    
    onSendInvitesPressed = () => {
        
        Api.selected = this.getSelection();
        this.props.history.replace('invites', this.state);
        this.props.history.push('addCC');
    };
    
    onRegisterPressed = () => {
        Api.selected = this.getSelection();
        
        
        Api.sendTo = Api.selected.map(item=>{
           return item.guid;
        });
        this.props.history.push('registerConfirmation');
    };
    
    onSyncPressed = () => {
        console.log("Sync Pressed");
        this.setState({syncing:true});
        Api.call("/clients/import","POST",{})
        .then()
        .catch(error=>{
            console.log(error);
        }).finally(()=>{
            this.setState({syncing:false});
        })
    };
    
    
    onUpdatePressed = () => {
        
        let guids = this.getSelection().map(client=>{
            return client.guid;
        });
        let csv = guids.join(",");
        this.setState({updating:true});
        Api.call("/clients/update","POST",{guids:csv})
        .then(()=>{
            this.loadData()
        })
        .catch(error=>{
            console.log(error);
        }).finally(()=>{
            this.setState({updating:false});
        })
        
    };
    
    checkAll = () => {
        let rows = this.state.rows.map(item => {
            item.checked = !this.state.checkAll;
            return item;
        });
        
        this.setState({rows: JSON.parse(JSON.stringify(rows)), checkAll: !this.state.checkAll});
        this.table.current.update();
    };
    
    loadData = () => {
        this.setState({loading: true});
        Api.call(`/invites/clients?limit=${this.state.perPage}&page=${this.state.currentPage}&keyword=${this.state.keyword}&sort=${this.state.sort}&sortDirection=${this.state.sortDirection === true ? "ASC" : "DESC"}`)
        .then(this.onDataLoaded)
        .catch((error) => {
            this.setState({error: error});
        })
    };
    
    onSearchInputChanged = (event) => {
        this.setState({keyword: event.target.value}, this.loadData);
    };
    
    onPageChanged = (page) => {
        console.log("Page changed to " + page);
        this.setState({currentPage: page}, this.loadData);
    };
    
    onPerPageChanged = (evt) => {
        let perPage = evt.target.value;
        console.log("Per Page changed to " + perPage);
        this.setState({perPage: perPage}, this.loadData);
    };
    
    componentWillUnmount() {
        console.log("Component will unmount");
        
    }
    
    onChange = (index, checked) => {
        this.state.rows[index].checked = checked;
        this.setState({},
            () => {
                this.table.current.update();
            });
    };
    
    
    onSort = (sort, sortDirection) => {
        console.log("Sort Changed: " + sort + ", direction:" + sortDirection);
        this.setState({sort: sort, sortDirection: sortDirection}, () => {
            this.loadData();
        });
    };
    
    render() {
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card bg-light">
                        
                        <TableHeader heading="Clients" searchKeyword={this.state.keyword} loading={this.state.loading}
                                  syncing={this.state.syncing}  showSync={true}  onSyncPressed={this.onSyncPressed}  onSearchInputChanged={this.onSearchInputChanged}/>
                        
                        <div className="card-body bg-white text-center p-0">
                            {this.state.loading === false && this.state.rows.length === 0 &&
                            <div className="noRequest">No records found</div>
                            }
                            {this.state.rows.length > 0 &&
                            <Table items={this.state.rows} columns={this.state.columns} ref={this.table}
                                   onChange={this.onChange} sort={this.state.sort}
                                   sortDirection={this.state.sortDirection} onSort={this.onSort}/>
                            }
                        </div>
                        
                        
                        <div className="card-footer bg-white  border">
                            <div className="float-left">
                                <input type="checkbox" id="checkAll" checked={this.state.checkAll}
                                       onChange={this.checkAll} value="1"/>
                                <label htmlFor="checkAll" className="ml-2">Select All</label>
                            </div>
                            <div className="float-right">
                                <Link className="btn btn-dark blueBtn mr-2" onClick={this.onUpdatePressed}
                                      buttonText={this.state.updating===true?"Updating...":"Update"} disabled={this.getSelection().length === 0}/>
                                <Link className="btn btn-dark blueBtn mr-2" onClick={this.onSendInvitesPressed}
                                      buttonText="Send Invites" disabled={this.getSelection().length === 0}/>
                                <Link className="btn btn-dark blueBtn mr-2" onClick={this.onRegisterPressed}
                                      buttonText="Register" disabled={this.getSelection().length === 0}/>
                            
                            </div>
                        </div>
                    
                    </div>
                    <div className="w-100 float-left mt-3 ">
                        <div className="float-left bg-light w-100 mb-2 border">
                            <Pagination onPageChanged={this.onPageChanged} totalRecords={this.state.totalRecords}
                                        pageSize={10} perPage={this.state.perPage} currentPage={this.state.currentPage}
                                        onPerPageChanged={this.onPerPageChanged}/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Clients);
