import React, {Component} from 'react';
import Clients from "./Invites/Clients/Clients";


class Layouts extends Component {
    render() {
        return (

            <Clients/>

        );
    }
}

export default Layouts;