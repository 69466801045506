import React, {Component} from 'react';
import Link from "../../components/Link/Link";
import ReactModal from 'react-modal';
import Api from "../../../helpers/Api";


class Table extends Component {
    
    
    constructor() {
        super();
        this.state = {
            showModal: false,
        };
        
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
    }
    
    handleOpenModal(event) {
        console.log(event);
        Api.call("/invites/logs/" + event.id)
        .then(event => {
            this.setState({event: event});
        });
        this.setState({showModal: true, event: event});
    }
    
    handleCloseModal() {
        this.setState({showModal: false});
    }
    
    
    render() {
        let items = this.props.items;
        let columns = this.props.columns;
        
        const tbody = items.map((item) => {
            return (
                
                <tr key={item.id.toString()}>
                    <td>
                        {item.company_name}
                    </td>
                    <td>
                        {item.internal_id}
                    </td>
                    <td>
                        {item.first_name} {item.last_name}
                    </td>
                    <td>
                        {item.email}
                    </td>
                    <td>
                        {item.phone}
                    </td>
                    <td>
                        {item.action}
                    </td>
                    <td>
                        {new Date(item.created_at).toLocaleDateString('en-US') + ", " + new Date(item.created_at).toLocaleTimeString('en-US')}
                    </td>
                    <td>
                        {item.action === 'Registration Failed' &&
                        <Link onClick={() => {
                            this.handleOpenModal(item);
                        }} buttonText={"Details"}/>
                        }
                    </td>
                
                
                </tr>
            )
        });
        
        const thead = columns.map((item) =>
            
            <th key={item.name.toString()} onClick={() => {
                if (!item.sortable) {
                    return;
                }
                let direction = this.props.sort === item.key ? !this.props.sortDirection : true;
                this.props.onSort(item.key, direction);
            }} className={item.key === this.props.sort ? this.props.sortDirection ? "topBorder" : "buttomBorder" : ""}>
                {item.name}
            </th>
        );
        
        
        return (
            <div>
                <ReactModal
                    style={{
                        overlay: {
                            zIndex: 100
                        },
                        content: {
                            zIndex: 100,
                            top: '50%',
                            left: '50%',
                            width: '500px',
                            right: 'auto',
                            bottom: 'auto',
                            marginRight: '-50%',
                            transform: 'translate(-50%, -50%)'
                        }
                    }}
                    isOpen={this.state.showModal}
                    contentLabel="Event Details"
                    onRequestClose={this.handleCloseModal}
                >
                    {this.state.event && this.state.event.data &&
                    <table className={"table table-bordered"}>
                        <tr>
                            <td>Step:</td>
                            <td>{this.state.event.data.step}</td>
                        </tr>
                        <tr>
                            <td>Error:</td>
                            <td>{JSON.stringify(this.state.event.data.error)}</td>
                        </tr>
                        <tr>
                            <td>Additional Data:</td>
                            <td>{JSON.stringify(this.state.event.data.data)}</td>
                        </tr>
                    </table>
                    }
                    
                    <button className={'pull-right'} style={{float:'right'}} onClick={this.handleCloseModal}>Close</button>
                </ReactModal>
                <table className="table table-borderless border mb-0 border-top-0">
                    <thead>
                    <tr>
                        {thead}
                    </tr>
                    </thead>
                    <tbody>
                    {tbody}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Table;
