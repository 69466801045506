import React, {Component} from 'react';

class Search extends Component {

    render() {
        return (
            <form>
                <div className="input-group searchInputGroup">
                    <input value={this.props.searchKeyword} type="text" className="form-control" onChange={this.props.onSearchInputChanged} placeholder="Search"
                           aria-label="Search" aria-describedby="basic-addon2"/>
                    <div className="input-group-append">
                        <span className="btn searchForm btn-primary ">
                            <img src="../../../images/search.png" alt="searchIcon"/>
                        </span>
                    </div>
                </div>
            </form>
        );
    }
}

export default Search;