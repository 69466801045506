import React, {Component} from 'react';
import Api from "../../../helpers/Api";
import DefaultLoader from "../../components/Loader/DefaultLoader";
import {Redirect} from "react-router";
import withRouter from "react-router/es/withRouter";
import NavigationPrompt from "react-router-navigation-prompt";


class InvitationSuccess extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            sending: true,
            sent: 0,
            sendTo: Api.sendTo,
            output:{}
        }
    }
    
    componentWillMount() {
        console.log(this.state.sendTo);
        
        Api.call("/registration/register/direct", "POST", {guids: this.state.sendTo})
        .then(output => {
            this.setState({sending: false,output:output});
        });
        
       
    }
    
    render() {
        if (this.state.sendTo.length === 0) {
            return (
                <Redirect to="invites"/>
            )
        }
        if (this.state.sending) {
            return (
                
                
                <div id="content-wrapper" className="mt-3">
                    
                    <NavigationPrompt when={this.state.sending}>
                        {({onConfirm, onCancel}) => {
                            window.confirm("All invites are not yet sent. Do you really want to leave this page?") ? onConfirm() : onCancel();
                        }
                        }
                    </NavigationPrompt>
                    <div className="container-fluid">
                        
                        <div className="card mb-3">
                            <div className="card-body bg-white text-center pt-5 pb-5">
                                <DefaultLoader/>
                                <h4 className="font-weight-normal mt-4">
                                    Registering...
                                </h4>
                                <span>Registering {this.state.sendTo.length} clients...</span>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    
                    <div className="card mb-3">
                        <div className="card-body bg-white text-center pt-5 pb-5">
                            <img src="../../../images/sucess.png" alt="Success Imager"/>
                            <h4 className="font-weight-normal mt-4">
                                Done!
                            </h4>
                            <span>Success: {this.state.output.success.length}</span>
                            <br/>
                            <span>Failed: {this.state.output.failed.length}</span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(InvitationSuccess);
