// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import { Route} from 'react-router-dom'
import RegistrationHeader from "../Registration/Header/RegistrationHeader";

const RegistrationRoute = ({component: Component, ...rest}) => {

    return (
        <Route
            {...rest}
            render={props =>
               


                    <div className="wrapper bg-light">

                        <div className="FullWidth">
                            <RegistrationHeader user={rest.user}/>
                            <Component {...props} user={rest.user}/>
                        </div>
                    </div>


            }
        />
    )
};

export default RegistrationRoute