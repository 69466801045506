import React, {Component} from 'react';
import Table from "./Components/Table";
import Pagination from "../Invites/Clients/components/Pagination";
import Api from "../../helpers/Api";
import TableHeader from "../components/HeaderTable/TableHeader";

class InviteLogs extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            rows: [],
            rowsToDisplay: [],
            totalPages: 1,
            currentPage: 1,
            columns: [
                {name: "Company", key: "company_name", sortable: true},
                {name: "Acount #", key: "internal_id", sortable: true},
                {name: "User", key: "user", sortable: false},
                {name: "Email", key: "email", sortable: true},
                {name: "Phone", key: "phone", sortable: true},
                {name: "Action", key: "action", sortable: false},
                {name: "Date", key: "createdAt", sortable: true}
            ],
            error: null,
            perPage: 10,
            loading: true,
            keyword: '',
            totalRecords: 0,
            sort: 'createdAt',
            sortDirection: false
        }
    }
    
    onSearchInputChanged = (event) => {
        this.setState({keyword: event.target.value}, this.loadData);
    };
    
    showEventDetails = (event=>{
       alert(JSON.stringify(event));
    });
    
    loadData = () => {
        this.setState({loading: true});
        Api.call(`/invites/logs?limit=${this.state.perPage}&page=${this.state.currentPage}&keyword=${this.state.keyword}&sort=${this.state.sort}&sortDirection=${this.state.sortDirection === true ? "ASC" : "DESC"}`)
        .then(this.onDataAvailable).catch(error => {
            this.setState({error: error});
        })
    };
    
    onPerPageChanged = (evt) => {
        let perPage = evt.target.value;
        this.setState({perPage: perPage}, this.loadData);
    };
    
    onPageChanged = (page) => {
        this.setState({currentPage: page}, this.loadData);
    };
    
    onDataAvailable = (data) => {
        this.setState({totalRecords: data.totalRecords, rows: data.data, loading: false});
    };
    
    componentWillMount() {
        this.loadData();
    }
    
    onSort = (sort, sortDirection) => {
        console.log("Sort Changed: " + sort + ", direction:" + sortDirection);
        this.setState({sort: sort, sortDirection: sortDirection}, () => {
            this.loadData();
        });
    };
    
    
    render() {
        
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card">
                        
                        <TableHeader heading="Logs" searchKeyword={this.state.keyword} loading={this.state.loading}
                                     onSearchInputChanged={this.onSearchInputChanged}/>
                        
                        <div className="card-body bg-white text-center p-0">
                            {this.state.loading === false && this.state.rows.length === 0 &&
                            <div className="noRequest">No records found</div>
                            }
                            {this.state.rows.length > 0 &&
                            <Table showEventDetails={this.showEventDetails} items={this.state.rows} columns={this.state.columns} ref={this.table}
                                   onChange={this.onChange} sort={this.state.sort}
                                   sortDirection={this.state.sortDirection} onSort={this.onSort}/>
                            }
                        </div>
                    
                    </div>
                    <div className="w-100 float-left mt-3 ">
                        <div className="float-left bg-light w-100 mb-2 border">
                            <Pagination onPageChanged={this.onPageChanged} totalRecords={this.state.totalRecords}
                                        pageSize={2} perPage={this.state.perPage} currentPage={this.state.currentPage}
                                        onPerPageChanged={this.onPerPageChanged}/>
                        </div>
                    </div>
                </div>
            </div>
        
        );
    }
}

export default InviteLogs;
