import React, {Component} from 'react';
import Loader from 'react-loader-spinner'

class DefaultLoader extends Component {
    render() {
        return (
            <div>
                {this.props.text &&
                <span>{this.props.text}</span>
                }
                <Loader type="TailSpin" color="#000000" height={40} width={40}/>
            </div>
        );
    }
}

export default DefaultLoader;