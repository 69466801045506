import React, {Component} from 'react';

class RegistrationHeader extends Component {
    render() {

        // eslint-disable-next-line
        const {children, ...attributes} = this.props;

        return (
            <nav className="navbar navbar-expand navbar-light bg-white static-top fullWidth fixOnTop">

                <button type="button" className="navbar-brand mr-1" >
                    <img height="40" src="../../images/Zingit-Solutions.png" alt="Logout Icon"/>
                </button>

                <div className="d-none d-md-inline-block form-inline ml-auto mr-0  my-2 my-md-0">
                    <ul className="user">
                        <li>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default RegistrationHeader;