import React, {Component} from 'react';
import {withRouter} from 'react-router';
import Api from "../../../helpers/Api";


class Aside extends Component {
    
    gotoRoute = (routeName) => {
        this.props.history.push(routeName);
    };
    
    
    onLogout = ()=>{
      Api.setUser(null);
      this.props.history.push('login');
    };
    
    render() {
    
        let currentRoute = 'this.props.location.pathname';
        
    
        return (
            <ul className="sidebar navbar-nav">
                <li className="logo">
                    <button type="button" onClick={()=>this.gotoRoute('invites')} >
                        <img  src="../../../images/white-logo.svg" alt="logo"/>
                    </button>
                </li>
                <li className={"nav-item " + (currentRoute === '/invites' ? 'active' : '')}>
                    <button type="button" className="nav-link"  onClick={()=>this.gotoRoute('invites')}>
                        <img src="../../../images/invite.png" alt="Invite Icon"/>
                        <span>Invites</span>
                    </button>
                </li>
                <li className={"nav-item " + (currentRoute === '/invites' ? 'active' : '')}>
                    <button type="button" className="nav-link"  onClick={()=>this.gotoRoute('import')}>
                        <img src="../../../images/invite.png" alt="Invite Icon"/>
                        <span>Import</span>
                    </button>
                </li>
                <li className={"nav-item " + (currentRoute === '/logs' ? 'active' : '')}>
                    <button type="button" className="nav-link" onClick={()=>this.gotoRoute('logs')}>
                        <img src="../../../images/invites-log.png" alt="Invite Log Icon"/>
                        <span>Invites Log</span>
                    </button>
                </li>
                <li className={"nav-item " + (currentRoute === '/requests' ? 'active' : '')}>
                    <button type="button" className="nav-link"  onClick={()=>this.gotoRoute('requests')}>
                        <img src="../../../images/requests.png" alt="Request Icon"/>
                        <span>Requests</span>
                    </button>
                </li>
                
                <li className="nav-item logOutBottom">
                    <button type="button" className="nav-link" onClick={this.onLogout}>
                        <img src="../../../images/logout.png" alt="Logout Icon"/>
                        <span>Logout</span>
                    </button>
                </li>
            </ul>
        );
    }
}


export default withRouter(Aside);
