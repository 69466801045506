import React, {Component} from 'react';

class Table extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            items: this.props.items,
            columns: this.props.columns,
        }
    }
    
    update() {
        let state = {
            items: this.props.items,
            columns: this.props.columns,
        };
        this.setState(state);
    }
    
    onItemClick = (index, checked) => {
        this.props.onChange(index, checked);
    };
    
    
    render() {
        
        let items = this.state.items;
        let columns = this.state.columns;
        
        
        const tbody = items.map((item, index) => {
            
            return (
                <tr key={item.id.toString()}>
                    <td>
                        <label htmlFor={item.id}>{item.company_name}</label>
                    </td>
                    <td>
                        <label htmlFor={item.id}>{item.first_name} {item.last_name}</label>
                    </td>
                    <td>
                        <label htmlFor={item.id}>{item.email}</label>
                    </td>
                    <td>
                        <input onChange={(event) => this.props.onChangeCC(index, event.target.value)}/>
                    </td>
                </tr>
            )
        });
        
        const thead = columns.map((item) =>
            <th key={item.name.toString()} onClick={() => {
                if (!item.sortable) {
                    return;
                }
                let direction = this.props.sort === item.key ? !this.props.sortDirection : true;
                this.props.onSort(item.key, direction);
            }} className={item.key === this.props.sort ? this.props.sortDirection ? "topBorder" : "buttomBorder" : ""}>
                {item.name}
            </th>
        );
        
        
        return (
            <table
                className="table table-borderless withLabel table-hover table-striped mb-0 border border-top-0 border-bottom-0">
                <thead>
                <tr key="1">
                    {thead}
                </tr>
                </thead>
                <tbody>
                {tbody}
                </tbody>
            </table>
        );
    }
}

export default Table;
