import React, {Component} from 'react';
import Api from "../../../helpers/Api";
import withRouter from "react-router/es/withRouter";


class Header extends Component {
    
    onLogout = ()=>{
        Api.setUser(null);
        this.props.history.push('login');
    };
    
    render() {

        // eslint-disable-next-line
        const {children, ...attributes} = this.props;

        return (
            <nav className="navbar navbar-expand navbar-light bg-white static-top fixOnTop">

                <span className="navbar-brand mr-1">Welcome to Zingit Solution</span>

                <div className="d-none d-md-inline-block form-inline ml-auto mr-0  my-2 my-md-0">
                    <ul className="user">
                        <li>
                            {this.props.user.name}
                        </li>
                        <li>
                            <button type="button" onClick={this.onLogout} className="logOutIcon">
                                <img width="24px" src="../../images/logout.png" alt="Logout Icon"/>
                            </button>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default withRouter(Header);
