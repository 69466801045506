import React from 'react';

const Resent = ({onResend}) => {
    return (
        <div className="flex-row registrationForm">
            <div className="card loginCard position-relative">
                <div className="card-body pl-0 pr-0">
                    <div className="content">
                        <div className="feedBackLogo text-center mb-3">
                            <img width="100px" src="../../../../images/reputationPro.png" alt="feedBackIcon"/>
                        </div>
                        <div className="registerCardText p-2">
                            <img width="32px" src="../../../../../images/checked.png" alt="Checked Icon"/>
                            <p className="font-weight-normal mt-4 text-center">
                                An request to resend the invitation has been sent to admin for approval.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Resent;