// This is used to determine if a user is authenticated and
// if they are allowed to visit the page they navigated to.

// If they are: they proceed to the page
// If not: they are redirected to the login page.
import React from 'react'
import {Redirect, Route} from 'react-router-dom'
import DefaultAside from "./Aside/Aside";
import Header from "./Header/Header";

const PrivateRoute = ({component: Component, ...rest}) => {
    
    return (
        <Route
            {...rest}
            render={props =>
                rest.user ? (
    
    
                    <div className="wrapper">
                        <DefaultAside/>
        
                        <div className="containerRight">
                            <Header user={rest.user}/>
                            <Component {...props} user={rest.user}/>
                        </div>
                    </div>
                    
                    
                    
                ) : (
                    <Redirect to={{pathname: '/login', state: {from: props.location}}}/>
                )
            }
        />
    )
};

export default PrivateRoute