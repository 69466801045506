import React, {Component} from 'react';

class NotificationDetail extends Component {
    render() {
        return (
            <div className="card-header bg-white">
                <div className="text-center pt-4 pb-4">
                    <img src="../../../../images/notification.png" alt="Notification Imager"/>
                    <h4 className="font-weight-normal mt-4">
                        Notification
                    </h4>
                    <span>Following {this.props.total} people have already been sent invitations.</span>
                </div>
            </div>
        );
    }
}

export default NotificationDetail;