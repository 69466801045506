import React, {Component} from 'react';
import TableHeader from "../components/HeaderTable/TableHeader";
import Link from "../components/Link/Link";
import ReactFileReader from 'react-file-reader';
import Api from "../../helpers/Api";
import LoaderSmall from "../components/Loader/LoaderSmall";
import Table from "../Invites/Clients/components/Table";

class Import extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [], clients: [], loading: false,

            columns: [
                {name: "Company", key: "company_name", sortable: true},
                {name: "User", key: "user", sortable: true},
                {name: "Email", key: "email", sortable: true},
                {name: "Phone", key: "phone", sortable: true}
            ]
        };
        this.table = React.createRef();
    }

    componentWillMount() {

    }

    handleFiles = (file) => {
        let lines = atob(file.base64.split(',')[1]).split("\n");
        this.setState({data: lines, loading: true});
        this.loadMatchingClients();

    };

    loadMatchingClients = () => {
        this.setState({loading: true});
        Api.call(`/invites/clients/csv`,"POST",{guids:this.state.data.join(",")})
            .then(response => {
                this.setState({clients: response.data, loading: false});
            }).catch(error => {
            this.setState({error: error});
        })
    };


    onSend = () => {
        Api.selected = this.getSelection();
        this.props.history.push('addCC');
    };

    getSelection = () => {
        return this.state.clients.map(item => {
            return item
        });
    };


    render() {
        return (
            <div id="content-wrapper" className="mt-3">
                <div className="container-fluid">
                    <div className="card">
                        <TableHeader heading="Import CSV" hideSearch={true}/>

                        <div className="card-body bg-white text-left d-flex flex-column p-0 position-relative">
                            <ReactFileReader fileTypes={[".csv"]} base64={true} multipleFiles={false}
                                             handleFiles={this.handleFiles}>
                                <div className="p-10 color-gray">
                                    <button id="uploadBtn" className='uploadBtn mr-1'>Upload</button>
                                    <label for="uploadBtn">Choose File</label>
                                </div>
                            </ReactFileReader>
                            {this.state.loading &&
                            <div className="mt-2 loaderPosition">
                                <LoaderSmall/>
                            </div>
                            }
                            {this.state.clients.length > 0 &&
                            <Table items={this.state.clients} columns={this.state.columns} showSelectBox={false}/>
                            }
                        </div>

                        {this.state.clients.length > 0 &&
                        <div className="card-footer bg-white border">

                            <div className="float-right">
                                <Link className="btn btn-dark blueBtn mr-2" buttonText="Next"
                                      disabled={this.state.clients.length === 0}
                                      onClick={this.onSend}/>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Import;
