export default class Api {
  static user = null;
  static selected = [];
  static sendTo = [];

  //static BASE_URL = 'http://reputation-pro.itmatic.io:3001/api/v1';
  //static BASE_URL = 'https://iy5s3asv1f.execute-api.us-east-1.amazonaws.com/dev/api/v1';
  // static BASE_URL = 'https://skflqonuo4.execute-api.us-east-1.amazonaws.com/dev/api/v1';
  //static BASE_URL = 'http://127.0.0.1:3001/api/v1';
  static BASE_URL =
    "https://l9t638ud6c.execute-api.us-west-2.amazonaws.com/production/api/v1";

  static setUser(user) {
    Api.user = user;
    localStorage.setItem("user", JSON.stringify(user));
  }

  static getUser() {
    console.log("In getUser");
    if (Api.user) {
      return Api.user;
    }
    Api.user = JSON.parse(localStorage.getItem("user"));
    return Api.user;
  }

  static call(url, method = "GET", data) {
    let BASE_URL = "";
    if (process.env.REACT_APP_ENV === "local") {
      BASE_URL = "http://localhost:3001/api/v1";
    } else if (process.env.REACT_APP_ENV === "qa") {
      BASE_URL =
        "https://wig3o4go14.execute-api.us-west-2.amazonaws.com/qa/api/v1";
    } else {
      BASE_URL =
        "https://l9t638ud6c.execute-api.us-west-2.amazonaws.com/production/api/v1";
    }
    if (method === "POST" && Api.user) {
      data.accessToken = Api.user.password;
    }
    if (method === "GET") {
      if (url.indexOf("?") === -1) {
        url += "?";
      } else {
        url += "&";
      }
      if (Api.user) {
        url += "accessToken=" + Api.user.password + "&";
      }
    }

    console.log("Calling API: " + method + " " + url);
    if (data) {
      console.log(data);
    }

    return new Promise((resolve, reject) => {
      fetch(BASE_URL + url, {
        method: method || "GET",
        body: JSON.stringify(data),
        mode: "cors",
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          Accept: "application/json",
        },
      })
        .then((response) => {
          // response only can be ok in range of 2XX
          if (response.ok) {
            // you can call response.json() here too if you want to return json
            resolve(response.json());
          } else {
            //handle errors in the way you want to

            switch (response.status) {
              case 404:
                console.log("Object not found");
                break;
              case 500:
                console.log("Internal server error");
                break;
              default:
                console.log("Some error occured");
                break;
            }

            response.json().then((json) => {
              return reject(json.error);
            });
          }
        })
        .catch((error) => {
          //it will be invoked mostly for network errors
          //do what ever you want to do with error here
          console.log(error);
          reject("Error");
        });
    });
  }
}
