import React, {Component} from 'react';
import Link from "../../../components/Link/Link";

class NotificationButton extends Component {
    render() {
        return (
            <div className="card-footer bg-white  border">
                <div className="float-left">
                    <Link onClick={this.props.onSkip} className="btn btn-dark outLineBtn" buttonText="Skip" disabled={!this.props.skipActive}/>
                </div>
                <div className="float-right">
                    <Link onClick={this.props.onResend} disabled={this.props.checked === 0} className="btn btn-dark blueBtn mr-2" buttonText="Resend" />
                    <Link className="btn btn-dark redBtn" buttonText="Cancel" onClick={this.props.onCancel}/>
                </div>
            </div>
        );
    }
}

export default NotificationButton;
