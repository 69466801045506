import React, {Component} from 'react';
import {withRouter} from 'react-router';
import Link from "../../../components/Link/Link";

class InvitationDetail extends Component {
    
    onSend = () => {
        this.props.history.push('sent');
    };
    cancel = ()=>{
        this.props.history.push('invites');
    };
    
    render() {
        return (
            <div className="card mb-3">
                <div className="card-body bg-white text-center pt-5 pb-5">
                    <img src="../../../../images/invitation.png" alt="Invitaion Imager"/>
                    <h4 className="font-weight-normal mt-4">
                        Invitation
                    </h4>
                    <span>Are you sure you want to send invite to {this.props.total} users?</span>
                </div>
                <div className="card-footer bg-white text-right">
                    <Link className="btn btn-dark blueBtn mr-2" onClick={this.props.onSendConfirmation}
                          buttonText="Send"/>
                    <Link className="btn btn-dark redBtn" onClick={this.cancel}
                          buttonText="Cancel"/>
                </div>
            </div>
        
        );
    }
}

export default withRouter(InvitationDetail);
