import React, {Component} from 'react';

class Link extends Component {
    render() {
        return (
            <button type="button" onClick={this.props.onClick} className={this.props.className}
                    disabled={this.props.disabled || false}>
                {this.props.buttonText}
            </button>
        );
    }
}

export default Link;
