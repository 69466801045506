import React, {Component} from 'react';
import Loader from 'react-loader-spinner'

class LoaderSmall extends Component {
    render() {
        return (
            <Loader type="TailSpin" color="#000000" height={20} width={20}/>
        );
    }
}

export default LoaderSmall;