import React, {Component} from 'react';

class Checkbox extends Component {
    render() {
        return (
            <div className="float-left w-100 readableInputs d-flex">
                <input type="checkbox" className="mr-2 mt-1" id={this.props.id} onChange={this.props.onChange}/>
                <label className="checkLabel" htmlFor={this.props.id}>{this.props.label}</label>
            </div>
        );
    }
}

export default Checkbox;
