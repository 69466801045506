import React, {Component} from 'react';

var pagination = require('pagination-logic');


class Pagination extends Component {
    render() {
        
        let paginationOptions = {
            total: this.props.totalRecords,
            single: this.props.perPage,
            pageSize: this.props.pageSize,
            currentPage: this.props.currentPage,
            pageLinkRule: () => {
            }
        };
        
        console.log(paginationOptions);
        
        let p = pagination(
            paginationOptions
        );
        

        const pages = p.pages.map((page) => {
                let item = page.number;
                return (
                    <li key={item.toString()}>
                        {this.props.currentPage !== item &&
                        <button className="page-link" onClick={() => {
                            this.props.onPageChanged(item)
                        }}>{item}</button>
                        }
                        {this.props.currentPage === item &&
                        <span>{item}</span>
                        }
                    </li>
                );
            }
        );
        
        let firstRecord = (this.props.currentPage - 1) * this.props.perPage;
        let lastRecord = (this.props.currentPage) * this.props.perPage;
        
        firstRecord = firstRecord < 1 ? 1 : firstRecord;
        lastRecord = lastRecord > this.props.totalRecords ? this.props.totalRecords : lastRecord;
        
        firstRecord = firstRecord > this.props.totalRecords ?this.props.totalRecords : firstRecord;
        
        return (
            <div className="p-2 float-left w-100">
                <div className="float-left SelectPaginatiom">
                    <select value={this.props.perPage} onChange={this.props.onPerPageChanged} id="select">
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={this.props.totalRecords}>All</option>
                    </select>
                    <span className="ml-2">
                        Displaying records {firstRecord} - {lastRecord} of {this.props.totalRecords}
                    </span>
                </div>
                
                <div className="float-right d-flex ">
                    
                    <ul className="pagination mb-0">
                        {p.hasPrevious &&
                        <button className="page-link" onClick={() => {
                            this.props.onPageChanged(this.props.currentPage - 1)
                        }}>&lt;</button>
                        }
                        {pages}
                        {p.hasNext &&
                        <button className="page-link" onClick={() => {
                            this.props.onPageChanged(this.props.currentPage + 1)
                        }}>&gt;</button>
                        }
                    </ul>
                </div>
            </div>
        );
    }
}

export default Pagination;